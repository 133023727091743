<template>
  <div>
    <div class="row justify-content-start" slot="customFilters">
      <div class="col-sm-12 col-md-6 col-lg-4" >
        <b-form-group id="fromPickup-group" label="Fecha inicial"  label-for="fromPickup">
          <b-form-datepicker @input="fetchTable()" v-model="filterFromDate"
                             reset-button label-reset-button="Limpiar"
                             id="fromPickup" label="Fecha inicial" placeholder="Desde"/>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4" >
        <b-form-group id="toPickup-group" label="Fecha final"  label-for="toPickup">
          <b-form-datepicker @input="fetchTable()" v-model="filterToDate"
                             reset-button label-reset-button="Limpiar"
                             id="toPickup" label="Fecha final" placeholder="Hasta"/>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-4" >
        <globalPlantFilter @onChangeValue="fetchTable"  @filterValue="filterPlant = $event"/>
      </div>
    </div>
    <a-table :columns="tableObject.columns"
             :data-source="tableObject.data"
             :pagination="tableObject.pagination"
             :loading="tableObject.loading"
             @change="fetchTable"
             :row-key="record => record.id" bordered
    >
      <template slot="expandedRowRender" slot-scope="record">
          <small>
            <strong>
              {{ `Comentarios: ` + ' ' }}
            </strong>
            <strong>
              {{ (record.output_comments) ? record.output_comments : '-' }}
            </strong>
          </small>
      </template>
      <template slot="qrCode" slot-scope="data">
        <span>{{ (data.code) ? data.code : 'Sin asignar' }} </span>
      </template>
      <template slot="generalData" slot-scope="data">
        <span class="text-center font-weight-bold">
          Tipo de ingreso:
        </span>
        <!-- <br/> -->
        {{ data.receipt_samples.invoice_code }}
        <br/>
        <!-- <hr class="bg-primary"> -->
        <span class="text-center font-weight-bold">
          Cliente/solicitante:
        </span>
        <!-- <br/> -->
        {{ data.receipt_samples.lab_customer }}
        <br/>
        <!-- <hr class="bg-primary"> -->
        <span class="text-center font-weight-bold">
          Código de OT:
        </span>
        {{ data.receipt_samples.code }}
        <br/>
        <!-- <hr class="bg-primary"> -->
        <span class="text-center font-weight-bold">
          Origen/Procedencia:
        </span>
        {{ data.receipt_samples.work_centers.code }} - {{ data.receipt_samples.work_centers.name }}
        <br/>
        <!-- <hr class="bg-primary"> -->
        <!-- <span class="text-center font-weight-bold">
          Tipo de Contenedor:
        </span>
        {{ 'valor 5' }} -->
      </template>
      <template slot="samplesData" slot-scope="data">
        <div class='row'>
          <div class='col'>
            <span class="text-center font-weight-bold">
              Residuo:
            </span>
            {{ ' ' + data.receipt_samples.sample_material }}
            <br/>
            <span class="text-center font-weight-bold">
              Cantidad de muestra tomada:
            </span>
            {{ ' ' + data.receipt_samples.quantity }}
            <br/>
            <span class="text-center font-weight-bold">
              Piezas almacenadas:
            {{ ' 1' }}
            </span>
            <br/>
            <span class="text-center font-weight-bold">
              Tipo de Contenedor de la Muestra:
            </span>
            {{ data.sample_container_types.name }}
            <br/>
          </div>
          <a-divider type="vertical" style="height: auto; background:#20a367;"/>
          <div class='col'>
            <span class="text-center font-weight-bold">
              Peligro Potencial:
            </span>
            {{ data.potential_dangerous }}
            <br/>
            <span class="text-center font-weight-bold">
              Área de Almacenamiento:
            </span>
            {{ data.lab_allocations.code }} - {{ data.lab_allocations.name }}
            <br/>
            <span class="text-center font-weight-bold">
              Tiempo de Almacenamiento:
            </span>
            {{ data.lab_storage_times.name }}
          </div>
        </div>
      </template>
      <template slot="dates" slot-scope="data">
        <span class="font-weight-bold">
          Fecha de entrada al almacén:
        </span>
        {{
          moment(data.receipt_samples.receipt_sample_date.set_warehouse_dated_at).format('YYYY-MM-DD HH:mm a')
        }}
        <br/>
        <span class="font-weight-bold">
          Fecha de caducidad:
        </span>
        {{
          moment(data.expiration_dated_at).format('YYYY-MM-DD HH:mm a')
        }}
        <br/>
        <span class="font-weight-bold">
          Fecha de baja:
        </span>
        {{
          moment(data.output_dated_at).format('YYYY-MM-DD')
        }}
        <br/>
        <span class="font-weight-bold">
          Responsable de baja:
        </span>
        {{
          data.output_responsible
        }}
      </template>
      <template slot="estatus">
        <span class="text-danger font-weight-bold">
          {{ 'BAJA' }}
        </span>
        <br/>
      </template>
    </a-table>
    <br/>
  </div>
</template>

<script>
import moment from 'moment'
import { fractalMixin } from '@/mixins/fractalMixin'
import { mapGetters } from 'vuex'
export default {
  name: 'warehouseTable',
  mixins: [fractalMixin],
  components: { },
  data() {
    return {
      // Modal
      showModal: false,
      whOperation: true,
      spinnerLoad: false,
      // Agregar codigo modal
      codeForm: {
        number: undefined,
      },
      load: false,
      // Variables de filtros
      filterFromDate: '',
      filterToDate: '',
      filterArea: '',
      filterPlant: '',
      filterEstatus: '',
      // Variables de combos
      workCenters: [],
      AreasCombo: [],
      UsersCombo: [],
      EstatusCombo: [
        {
          id: 1,
          name: 'Caducada',
        },
        {
          id: 2,
          name: 'No caducada',
        },
      ],
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Código',
            key: 'code',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'qrCode' },
          },
          {
            title: 'Datos Generales',
            key: 'datosgenerales',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'generalData' },
          },
          {
            title: 'Datos de la muestra',
            key: 'samplesData',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'samplesData' },
          },
          {
            title: 'Fechas',
            key: '',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'dates' },
          },
          {
            title: 'Estatus',
            key: '',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'estatus' },
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const params2 = {
        orderField: 'id',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
        {
          path: '/fetch/labAllocations',
          params: params2,
        },
        {
          path: '/fetch/users',
          params: params1,
        },
      ]
      const [plants, labs, users] = await this.GetConcurrencyResources(arr)
      this.workCenters = plants
      this.AreasCombo = labs
      this.UsersCombo = users
    },
    async fetchTable(pagEvent = {}) {
      const params = {
        existenceStatus: 'Salida',
        orderType: 'asc',
        orderField: 'code',
      }
      // Todo: Verificar funcionamiento de filtros de table
      if (this.filterPlant) params.workCenterFilter = this.workCenters.find(x => x.code === this.filterPlant).id // cliente
      if (this.filterFromDate) params.initialDate = moment(this.filterFromDate).format('YYYY-DD-MM') // fecha inicial
      if (this.filterToDate) params.finalDate = moment(this.filterToDate).format('YYYY-DD-MM') // fecha final
      if (this.filterValue) params.search = this.filterValue
      await this.GetTableResourcev2(pagEvent, 'sampleStorageAllocations', this.tableObject, params).then(
        () => {
          this.tableObject.data.forEach(element => {
            if (moment.utc(moment(element.expiration_dated_at)) < moment.utc()) {
              element.caducidad = true
            } else {
              element.caducidad = false
            }
          })
        },
      )
      // // console.log('Valor filtro: ', this.filterEstatus)
      if (this.filterEstatus !== '') {
        if (this.filterEstatus === 1) {
          this.tableObject.data = this.tableObject.data.filter(x => x.caducidad === true)
        } else {
          this.tableObject.data = this.tableObject.data.filter(x => x.caducidad === false)
        }
      }
    },
  },
  async mounted() {
    await this.fetchTable()
    await this.fetchCombos()
  },
}
</script>

<style scoped>

</style>
