<template>
  <div>
    <div class="air__utils__heading"> <h5>Almacén de Muestras</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <div class="row mb-5">
      <div class="col">
        <a-tabs default-active-key="1" type="card" size="large">
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="experiment"/>
              Existencias de muestras
            </span>
            <div class="card">
              <div class="card-body text-center">
                <warehouse-table/>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">
              <a-icon type="retweet" />
              Transacciones
            </span>
            <div class="card">
              <div class="card-body text-center">
                <transactionWareHouseTable />
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import WarehouseTable from '@/views/geocycle/laboratories/sample_warehouse/warehouseTable'
import TransactionWareHouseTable from '@/views/geocycle/laboratories/sample_warehouse/transactionWarehouseTable'
export default {
  name: 'sampleWarehouseMain',
  components: { TransactionWareHouseTable, WarehouseTable },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          breadcrumbName: 'Almacén de Muestras',
          aIcon: '',
        },
      ],
    }
  },
  methods: {},
  mounted() {},
}
</script>
