<template>
  <div>
    <div class="row justify-content-start" slot="customFilters">
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="fromPickup-group" label="Fecha inicial"  label-for="fromPickup">
          <b-form-datepicker @input="fetchTable()" v-model="filterFromDate"
                             reset-button label-reset-button="Limpiar"
                             id="fromPickup" label="Fecha inicial" placeholder="Desde"/>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="toPickup-group" label="Fecha final"  label-for="toPickup">
          <b-form-datepicker @input="fetchTable()" v-model="filterToDate"
                             reset-button label-reset-button="Limpiar"
                             id="toPickup" label="Fecha final" placeholder="Hasta"/>
        </b-form-group>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <globalPlantFilter @onChangeValue="fetchTable"  @filterValue="filterPlant = $event"/>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="area-group" label="Servicio" label-for="area">
          <b-form-select v-model="filterArea" :options="AreasCombo"
                         id="area" label="Servicio" @change="fetchTable()"
                         value-field="id" text-field="name">
            <template slot="first">
              <b-form-select-option value="" >Todos las Áreas</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
    </div>
    <div class="row" slot="customFilters">
      <div class="col-sm-12 col-md-6 col-lg-3" >
        <b-form-group id="area-group" label="Servicio" label-for="area">
          <b-form-select v-model="filterEstatus" :options="EstatusCombo"
                         id="area" label="Servicio" @change="fetchTable()"
                         value-field="id" text-field="name">
            <template slot="first">
              <b-form-select-option value="" >Todos los Estatus</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
    </div>
    <!-- <div style="padding: 10px">
      <a-row :gutter="10">
        <a-col :span="5">
          <a-card style="border-radius: 20px;">
            <a-statistic
              title="Área A - Refrigerador"
              :value="11"
              :precision="0"
              :value-style="{ color: '#3f8600' }"
              style="margin-right: 0px; color:#FF0000;"
            >
            </a-statistic>
          </a-card>
        </a-col>
        <a-col :span="5">
          <a-card style="border-radius: 20px">
            <a-statistic
              title="Área B - Análisis Básicos"
              :value="2"
              class="demo-class"
              :value-style="{ color: '#3f8600' }"
            >
            </a-statistic>
          </a-card>
        </a-col>
        <a-col :span="5">
          <a-card style="border-radius: 20px">
            <a-statistic
              title="Área C - Resguardo"
              :value="11"
              :precision="0"
              :value-style="{ color: '#3f8600' }"
              style="margin-right: 0px"
            >
            </a-statistic>
          </a-card>
        </a-col>
        <a-col :span="5">
          <a-card style="border-radius: 20px">
            <a-statistic
              title="Muestras para bajas de almacén"
              :value="11"
              :precision="0"
              :value-style="{ color: '#cf1322' }"
              style="margin-right: 0px"
            >
            </a-statistic>
          </a-card>
        </a-col>
        <a-col :span="4">
          <a-card style="border-radius: 20px">
            <a-statistic
              title="Muestras totales"
              :value="11"
              :precision="0"
              :value-style="{ color: '#3f8600' }"
              style="margin-right: 0px"
            >
            </a-statistic>
          </a-card>
        </a-col>
      </a-row>
    </div> -->
    <br/>
    <a-table :columns="tableObject.columns"
             :data-source="tableObject.data"
             :pagination="tableObject.pagination"
             :loading="tableObject.loading"
             @change="fetchTable"
             @openModal="openSampleModal"
             :row-key="record => record.id" bordered
    >
      <template slot="qrCode" slot-scope="data">
        <!-- <span>{{ data.codeQR }} -</span> -->
        <qrcode-vue v-show="data.code" :value="data.code" :size="50" level="H" />
        <span>{{ (data.code) ? data.code : 'Sin asignar' }} </span>
      </template>
      <template slot="generalData" slot-scope="data">
        <small>
          <span class="text-center font-weight-bold">
          Tipo de ingreso:
          </span>
          <!-- <br/> -->
          {{ data.receipt_samples.invoice_code }}
          <br/>
          <!-- <hr class="bg-primary"> -->
          <span class="text-center font-weight-bold">
            Cliente/solicitante:
          </span>
          <!-- <br/> -->
          {{ data.receipt_samples.lab_customer }}
          <br/>
          <!-- <hr class="bg-primary"> -->
          <span class="text-center font-weight-bold">
            Código de OT:
          </span>
          {{ data.receipt_samples.code }}
          <br/>
          <!-- <hr class="bg-primary"> -->
          <span class="text-center font-weight-bold">
            Origen/Procedencia:
          </span>
          {{ data.receipt_samples.work_centers.code }} - {{ data.receipt_samples.work_centers.name }}
          <br/>
        </small>
        <!-- <hr class="bg-primary"> -->
        <!-- <span class="text-center font-weight-bold">
          Tipo de Contenedor:
        </span>
        {{ 'valor 5' }} -->
      </template>
      <template slot="samplesData" slot-scope="data">
        <div class='row small'>
          <div class='col'>
            <span class="text-center font-weight-bold">
              Residuo:
            </span>
            {{ ' ' + data.receipt_samples.sample_material }}
            <br/>
            <span class="text-center font-weight-bold">
              Cantidad de muestra tomada:
            </span>
            {{ ' ' + data.receipt_samples.quantity }}
            <br/>
            <span class="text-center font-weight-bold">
              Piezas almacenadas:
            {{ ' 1' }}
            </span>
            <br/>
            <span class="text-center font-weight-bold">
              Tipo de Contenedor de la Muestra:
            </span>
            {{ data.sample_container_types.name }}
            <br/>
          </div>
          <a-divider type="vertical" style="height: auto; background:#20a367;"/>
          <div class='col'>
            <span class="text-center font-weight-bold">
              Peligro Potencial:
            </span>
            {{ data.potential_dangerous }}
            <br/>
            <span class="text-center font-weight-bold">
              Área de Almacenamiento:
            </span>
            {{ data.lab_allocations.code }} - {{ data.lab_allocations.name }}
            <br/>
            <span class="text-center font-weight-bold">
              Tiempo de Almacenamiento:
            </span>
            {{ data.lab_storage_times.name }}
          </div>
        </div>
      </template>
      <template slot="dates" slot-scope="data">
        <!--        {{ data }}-->
        <small>
          <span class="font-weight-bold">
            Fecha de entrada al almacén:
          </span>
          <!-- <br/> -->
          {{
            moment.utc(data.receipt_samples.receipt_sample_date.set_warehouse_dated_at).format('YYYY-MM-DD')
          }}
          <br/>
          <!-- <hr class="bg-primary"> -->
          <span class="font-weight-bold">
            Fecha de caducidad:
          </span>
          <!-- <br/> -->
          {{
            moment(data.expiration_dated_at).format('YYYY-MM-DD')
          }}
        </small>
      </template>
      <template slot="estatus" slot-scope="data">
        <small>
          <span class="text-center font-weight-bold">
            Caducidad:
          </span>
          <span v-if="data.caducidad === false"
                class="text-success font-weight-bold">
            {{ 'Sin caducar' }}
          </span>
          <span v-else class="text-danger font-weight-bold">
            {{ 'Caducada' }}
          </span>
          <br/>
        </small>
      </template>
      <!-- <template slot="status" slot-scope="data">
        <span class="font-weight-bold text-center">
          Estatus de muestra:
        </span>
        <br>
        <span v-if="data.sample_status === SAMPLE_PLAN_CHECK"
              class="text-success font-weight-bold"
        >
          <a-icon type="check-circle" />
          {{ data.sample_status }}
        </span>
        <span class="text-danger font-weight-bold" v-else>
          <a-icon type="clock-circle" />
          {{ data.sample_status }}
        </span>
        <hr class="bg-primary">
        <span class="font-weight-bold text-center">
          Estatus de almacén:
        </span>
        <br>
        <span v-if="data.warehouse_status === WAREHOUSE_STATUS_ENABLE || data.warehouse_status === WAREHOUSE_STATUS_FORMULATED"
              class="text-success font-weight-bold"
        >
          <a-icon type="check-circle" />
          {{ data.warehouse_status }}
        </span>
        <span v-else-if="data.warehouse_status === WAREHOUSE_STATUS_ANALYSIS"
              class="text-warning font-weight-bold"
        >
          <a-icon type="experiment" />
          {{ data.warehouse_status }}
        </span>
        <span v-else
              class="text-danger font-weight-bold"
        >
          <a-icon type="delete" />
          {{ data.warehouse_status }}
        </span>
      </template> -->
      <template slot="action" slot-scope="data">
        <a-button slot="avatar" @click="openDropWarehouseModal(data)" style="padding-vertical:10px" title="Dar de baja">
          <a-icon type="delete" theme="twoTone" two-tone-color="#FF0000" v-b-tooltip.hover />
        </a-button>
        <hr class="bg-primary">
        <a-button slot="avatar" @click="openAddWarehouseModal(data)" style="padding-vertical:20px;margin-right:7px" title="Añadir código">
          <a-icon type="edit" theme="twoTone" two-tone-color="#FF8000" v-b-tooltip.hover/>
        </a-button>
        <b-button slot="avatar" v-show="data.code" class="bg-transparent" style="padding-vertical:20px;border-color:rgba(111,111,111,0.2);" :to="{ name: 'testWarehouseQR', params: {id: data.id}}">
          <a-icon type="printer" theme="twoTone" two-tone-color="#FF8000" v-b-tooltip.hover/>
        </b-button>
        <!-- <router-link :to="{ name: 'mainLocations'}">
        </router-link> -->
        <!-- <a-button v-show="data.code" slot="avatar" style="padding-vertical:20px" title="Imprimir código QR" :to="{ name: 'testWarehouseQR', params: {id: data.id}}">
          <a-icon type="printer" theme="twoTone" two-tone-color="#FF8000" v-b-tooltip.hover/>
        </a-button> -->
        <!-- <div v-if="sampleProcess === 'receiptSampling'">
          <b-button variant="success" @click="openWarehouseModal(data)" pill>
            <div class="d-none d--block" v-b-tooltip.hover title="Ingresar al almacén">
              <b-icon icon="cart-plus"/> Ingresar al almacén
            </div>
            <div class="d--none" v-b-tooltip.hover title="Ingresar al almacén">
              <b-icon icon="house-fill"/>
            </div>
          </b-button>
          <b-button variant="secondary" @click="openSample('edit', data)" pill>
            <div class="d-none d--block" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/> Editar
            </div>
            <div class="d--none" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/>
            </div>
          </b-button>
          <a-popconfirm placement="topLeft" ok-text="SI" cancel-text="NO" @confirm="deleteSample(data)">
            <template slot="title">
              <p class="font-weight-bold text-danger">
                ¿Desea eliminar la muestra {{ data.code }}?
              </p>
              <p>Se eliminará la muestra seleccionada, este será un proceso irreversible,
                tenga cuidado en su elección.
              </p>
            </template>
            <a-button type="danger" shape="round" icon="delete" />
          </a-popconfirm>
        </div>
        <div v-else>
          NA
          <b-button variant="success" @click="openWarehouseModal(data)" pill>
            <div class="d-none d&#45;&#45;block" v-b-tooltip.hover title="Asignar Almacén">
              <b-icon icon="box-arrow-down"/>
              Asignar Almacén
            </div>
            <div class="d&#45;&#45;none" v-b-tooltip.hover title="Asignar Almacén">
              <b-icon icon="box-arrow-down"/>
            </div>
          </b-button>
        </div> -->
      </template>
    </a-table>
    <div class="row justify-content-end align-self-center">
      <b-modal size="lg"
       :title="(whOperation === true) ? 'Baja de almacén' : 'Agregar Muestra'"
       v-model="showModal" ref="modal-Form"
      >
        <my-spinner :load="spinnerLoad"/>
        <b-container fluid v-show="!spinnerLoad">
          <a-form-model v-if="whOperation === true" :model="resourceObj.form" :rules="formRules" ref="rule-Form"
              layout="vertical" :wrapper-col="{ span: 24}">
            <a-form-model-item label="Fecha de Baja" ref="output_dated_at"  prop="output_dated_at" >
              <b-form-datepicker v-model="resourceObj.form.output_dated_at"
                            reset-button label-reset-button="Limpiar"
                            id="output_dated_at" label="" placeholder="Fecha de baja"/>
            </a-form-model-item>
            <a-form-model-item label="Responsable de baja:"
                                  ref="output_responsible"
                                  prop="output_responsible"
            >
              <a-select v-model="resourceObj.form.output_responsible"
                        placeholder="Seleccionar usuario"
                        show-search
                        filter-option
              >
                <a-select-option  v-for="(option) in UsersCombo" :key="option.name" :value="option.name" >
                  {{option.name}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="Observaciones:"
                                  class="mx-1"
                                  ref="output_comments"
                                  prop="output_comments"
            >
              <a-textarea
                v-model="resourceObj.form.output_comments"
                placeholder="Observaciones"
                :auto-size="{ minRows: 2, maxRows: 5 }"
              />
            </a-form-model-item>
            <div class="row justify-content-end">
              <b-button-group>
                <b-button  variant="primary" @click="DropTest" pill>
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">2
                    <b-icon icon="archive"/>
                  </div>
                </b-button>
              </b-button-group>
            </div>
          </a-form-model>
          <a-form-model v-else :model="resourceObj1.form" :rules="formRules1" ref="rule-Form1"
              layout="vertical" :wrapper-col="{ span: 24}">
            <a-form-model-item label="Agregar código de muestra" ref="code" prop="code">
              <a-input class="block" placeholder="Código de muestra" v-model="resourceObj1.form.code"/>
            </a-form-model-item>
            <div class="row justify-content-end">
              <b-button-group>
                <b-button  variant="primary" @click="AddCode" pill>
                  <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/> Guardar
                  </div>
                  <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                    <b-icon icon="archive"/>
                  </div>
                </b-button>
              </b-button-group>
            </div>
          </a-form-model>
          <!-- <a-row v-show="whOperation === false" class="text-center">
            <a-row span="24">
              <a-form-model-item class="mt-2" v-show="whOperation === false">
                <a-row align="middle" justify="center">
                  <a-form-model-item label="Agregar código de muestra" ref="code" prop="code">
                    <a-input class="block" placeholder="Código de muestra" v-model="codeForm.number"/>
                  </a-form-model-item>
                </a-row>
              </a-form-model-item>
            </a-row>
          </a-row>
          <a-row v-show="whOperation === true" class="text-center">
            <a-row class="mb-0">
              <a-form-model-item label="Fecha de baja" ref="date" prop="date">
                <a-date-picker v-model="dropForm.date"
                :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" placeholder="Seleccione fecha"
                ></a-date-picker>
              </a-form-model-item>
            </a-row>
            <a-row class="mb-0">
              <a-col :lg="{ span: 24 }">
              </a-col>
            </a-row>
            <a-row class="mb-0">
            </a-row>
          </a-row> -->
        </b-container>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="closeSampleModal"/>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { fractalMixin } from '@/mixins/fractalMixin'
import { mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'warehouseTable',
  mixins: [fractalMixin],
  components: { QrcodeVue },
  data() {
    return {
      // Modal
      showModal: false,
      whOperation: true,
      spinnerLoad: false,
      // Agregar codigo modal
      codeForm: {
        number: undefined,
      },
      resourceObj: {
        resourceType: 'sampleStorageAllocations/output',
        form: {
          id: '',
          output_dated_at: '',
          output_responsible: '',
          output_comments: '',
        },
      },
      resourceObj1: {
        resourceType: 'sampleStorageAllocations',
        form: {
          id: '',
          code: '',
          potential_dangerous: '',
          sample_container_type_id: undefined,
          expiration_dated_at: '',
          lab_allocation_id: '',
          lab_storage_time_id: '',
        },
      },
      formRules: {
        output_dated_at: [{ required: true, message: 'La fecha es obligatoria.', trigger: 'blur' }],
        output_responsible: [{ required: true, message: 'El Código es obligatorio.', trigger: 'blur' }],
        output_comments: [{ required: true, message: 'Comentarios es obligatorio.', trigger: 'blur' }],
      },
      formRules1: {
        code: [{ required: true, message: 'El código es obligatoria.', trigger: 'blur' }],
      },
      load: false,
      // Variables de filtros
      filterFromDate: '',
      filterToDate: '',
      filterArea: '',
      filterPlant: '',
      filterEstatus: '',
      // Variables de combos
      workCenters: [],
      AreasCombo: [],
      UsersCombo: [],
      EstatusCombo: [
        {
          id: 1,
          name: 'Caducada',
        },
        {
          id: 2,
          name: 'No caducada',
        },
      ],
      tableObject: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Código',
            key: 'code',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'qrCode' },
          },
          {
            title: 'Datos Generales',
            key: 'datosgenerales',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'generalData' },
          },
          {
            title: 'Datos de la muestra',
            key: 'samplesData',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'samplesData' },
          },
          {
            title: 'Fechas',
            key: '',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'dates' },
          },
          {
            title: 'Estatus',
            key: '',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'estatus' },
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            // width: '20%',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  methods: {
    async fetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const params2 = {
        orderField: 'id',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/workCenters',
          params: params1,
        },
        {
          path: '/fetch/labAllocations',
          params: params2,
        },
        {
          path: '/fetch/users',
          params: params1,
        },
      ]
      const [plants, labs, users] = await this.GetConcurrencyResources(arr)
      this.workCenters = plants
      this.AreasCombo = labs
      this.UsersCombo = users
    },
    async fetchTable(pagEvent = {}) {
      const params = {
        existenceStatus: 'Entrada',
        orderType: 'asc',
        orderField: 'code',
      }
      // Todo: Verificar funcionamiento de filtros de table
      if (this.filterPlant) params.workCenterFilter = this.workCenters.find(x => x.code === this.filterPlant).id // cliente
      if (this.filterArea) params.labAllocationId = this.filterArea // corriente
      if (this.filterFromDate) params.initialDate = moment(this.filterFromDate).format('YYYY-DD-MM') // fecha inicial
      if (this.filterToDate) params.finalDate = moment(this.filterToDate).format('YYYY-DD-MM') // fecha final
      if (this.filterValue) params.search = this.filterValue
      await this.GetTableResourcev2(pagEvent, 'sampleStorageAllocations', this.tableObject, params).then(
        () => {
          this.tableObject.data.forEach(element => {
            if (moment.utc(moment(element.expiration_dated_at)) < moment.utc()) {
              element.caducidad = true
            } else {
              element.caducidad = false
            }
          })
        },
      )
      // // console.log('Valor filtro: ', this.filterEstatus)
      if (this.filterEstatus !== '') {
        if (this.filterEstatus === 1) {
          this.tableObject.data = this.tableObject.data.filter(x => x.caducidad === true)
        } else {
          this.tableObject.data = this.tableObject.data.filter(x => x.caducidad === false)
        }
      }
    },
    async DropTest() {
      this.$refs['rule-Form'].validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
          } else await this.PutResourcev2(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async AddCode() {
      this.$refs['rule-Form1'].validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj1.form.id === '') {
          } else await this.PutResourcev2(this.resourceObj1, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.fetchTable()
      this.closeSampleModal()
    },
    openSampleModal(data = {}) {
      this.showModal = true
    },
    openAddWarehouseModal(data = {}) {
      this.showModal = true
      this.whOperation = false
      this.spinnerLoad = true
      this.resourceObj1.form = {
        id: data.id,
        potential_dangerous: data.potential_dangerous,
        sample_container_type_id: data.sample_container_types.id,
        expiration_dated_at: data.expiration_dated_at,
        lab_allocation_id: data.lab_allocations.id,
        lab_storage_time_id: data.lab_storage_times.id,
      }
      this.spinnerLoad = false
    },
    openDropWarehouseModal(data = {}) {
      this.showModal = true
      this.whOperation = true
      this.spinnerLoad = true
      this.resourceObj.form = {
        id: data.id,
      }
      this.spinnerLoad = false
    },
    closeSampleModal(value) {
      this.showModal = value
    },
    CloseMaterialModal(data = {}) {
      this.modalShow = false
      // this.ResetData()
    },
  },
  async mounted() {
    await this.fetchTable()
    await this.fetchCombos()
  },
}
</script>

<style scoped>

</style>
